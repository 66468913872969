import { Directive, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';

@Directive({
	selector: 'groupui-input[btrGroupUiEmitChangeOnInput]',
	standalone: true,
})
export class GroupUiEmitChangeOnInputDirective implements OnDestroy {
	@HostListener('groupuiDidLoad') onGroupuiDidLoad() {
		this.attachInputListenerOnInput();
	}
	private sub!: Subscription;
	constructor(private elRef: ElementRef) {}

	attachInputListenerOnInput() {
		if (!this.sub) {
			const inputEl: HTMLInputElement = (this.elRef.nativeElement as HTMLElement).shadowRoot?.querySelector('input') as HTMLInputElement;
			if (inputEl) {
				this.sub = fromEvent(inputEl, 'input').subscribe(() => {
					(this.elRef.nativeElement as HTMLInputElement).value = inputEl.value;
					(this.elRef.nativeElement as HTMLElement).dispatchEvent(new CustomEvent('groupuiChange'));
				});
			}
		}
	}
	ngOnDestroy(): void {
		this.sub?.unsubscribe();
	}
}

import { groupBy, sortBy, trim } from 'lodash';

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TIMEZONES } from '@shared/timezone-select/timezone';

export interface TimezoneByContinent {
	name: string;
	timezones: Timezone[];
}
export interface Timezone {
	name: string;
	abbreviation: string;
	countryName?: string;
	continentName?: string;
	utcOffset?: string;
}

export const TIMEZONE_UTC: Timezone = {
	name: 'UTC',
	/*
	 * Exception, because name must be a timezone.
	 */
	abbreviation: 'Coordinated Universal Time',
	utcOffset: 'UTC+00:00',
};

@Injectable({
	providedIn: 'root',
})
export class DateTimeService {
	private readonly selectedTimezone = new BehaviorSubject<Timezone>(this.getBrowserTimezone());

	getBrowserTimezone() {
		const name = Intl.DateTimeFormat().resolvedOptions().timeZone;
		if (!name) {
			return TIMEZONE_UTC;
		}
		const timezone = this.findTimezone(name) as Timezone;
		return timezone ?? TIMEZONE_UTC;
	}

	findTimezone(name: string) {
		return TIMEZONES.find((item) => item.name === name);
	}

	filterTimezone(input: string, timezones = TIMEZONES): Timezone[] {
		const target = trim(input).toLowerCase();
		if (!target) {
			return timezones;
		}
		return timezones.filter((item) => {
			return [item.name, item.countryName, item.abbreviation].some((item) => item?.toLowerCase().includes(target));
		});
	}

	getTimezonesByContinent(timezones = TIMEZONES): TimezoneByContinent[] {
		return sortBy(
			Object.entries(groupBy(timezones, (item) => item.name.split('/')[0])).map(([name, timezones]) => {
				const res: TimezoneByContinent = { name, timezones: sortBy(timezones, (item) => item.name.split('/')[1]) };
				return res;
			}),
			'name',
		);
	}

	setTimezone(timezone: Timezone) {
		this.selectedTimezone.next(timezone);
	}

	getSelectedTimezone$() {
		return this.selectedTimezone.asObservable();
	}
}

import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'btr-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss'],
	standalone: true,
	imports: [CommonModule, GroupUiModule, RouterModule],
})
export class PageNotFoundComponent {}

import { Apollo, ApolloModule } from 'apollo-angular';

import { ErrorStoreService } from '@core/services/error-handling/error-store.service';
import { GraphqlMainService } from '@core/graphql/graphql-main.service';
import { HttpLink } from 'apollo-angular/http';
import { NGXLogger } from 'ngx-logger';
import { NgModule } from '@angular/core';

@NgModule({
	exports: [ApolloModule],
})
export class GraphQLModule extends GraphqlMainService {
	constructor(errorStore: ErrorStoreService, apollo: Apollo, httpLink: HttpLink, ngxLogger: NGXLogger) {
		super(errorStore, apollo, httpLink, ngxLogger);
	}
}

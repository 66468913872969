import { gql } from 'apollo-angular';

export const GQL_QUERY_CONTRACT_DETAILS = gql`
	query getContractFullDetailsById($contractId: String!) {
		getContractFullDetailsById(ContractId: $contractId) {
			ContractId
			ApproverPartnerId
			ApproverPartner {
				PartnerId
				PartnerLocation
				PartnerName
			}
			CreatorPartnerId
			CreatorPartner {
				PartnerId
				PartnerLocation
				PartnerName
			}
			CustomerPartnerId
			CustomerPartner {
				PartnerId
				PartnerLocation
				PartnerName
			}
			SupplierPartnerId
			SupplierPartner {
				PartnerId
				PartnerLocation
				PartnerName
			}
			BlobDefinition
			LastModified
			PartNumber
			PartType
			State
			TransactionHistory
			XSDSchemaReference
			KsuClass
			KsuTTL
		}
	}
`;

export const GQL_MUTATION_UPDATE_CONTRACT_STATE = gql`
	mutation updateContractState($input: UpdateContractStateRequest!) {
		updateContractState(input: $input) {
			ContractId
			State
		}
	}
`;

export const GQL_MUTATION_DELETE_CONTRACT = gql`
	mutation deleteContract($input: DeleteContractRequest!) {
		deleteContract(input: $input) {
			ContractId
		}
	}
`;

export const GQL_MUTATION_REGISTER_CONTRACT = gql`
	mutation registerContract($input: ContractRequest!) {
		registerContract(input: $input) {
			ContractId
		}
	}
`;

export const GQL_MUTATION_UPDATE_CONTRACT_XSD = gql`
	mutation updateContractXsd($input: UpdateContractXsdRequest!) {
		updateContractXSD(input: $input) {
			ContractId
		}
	}
`;

export const GQL_QUERY_CONTRACTS_BY_PARTNER_ID = gql`
	query getContractsByPartnerId($input: GetContractsByPartnerIdRequest) {
		getContractsByPartnerId(input: $input) {
			ContractId
			Customer
			LastModified
			PartNumber
			PartType
			State
			Supplier
		}
	}
`;

export const GQL_QUERY_CONTRACT_XSD_URL = gql`
	query getExportContractXsdById($contractId: String!) {
		exportContractXsdById(ContractId: $contractId) {
			XsdUrl
		}
	}
`;

export const GQL_QUERY_CONTRACT_HISTORY_BY_ID = gql`
	query contractHistoryById($contractId: String!) {
		contractHistoryById(contractId: $contractId) {
			ChangeDate
			UserId
			PartnerId
			PartnerName
			ChangeComment
		}
	}
`;

export const GQL_QUERY_CONTRACT_BY_PERMISSION = gql`
	query contractByPermission {
		contractByPermission {
			ContractId
			State
		}
	}
`;

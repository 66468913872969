import { ApolloLink, InMemoryCache, Operation } from '@apollo/client/core';
import { GraphQLErrors, NetworkError } from '@apollo/client/errors';

import { APOLLO_CLIENT_NAME } from '@core/enum/apollo-client-name.enum';
import { Apollo } from 'apollo-angular';
import { ErrorStoreService } from '@core/services/error-handling/error-store.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpLink } from 'apollo-angular/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { environment } from '@environments/environment';
import { onError } from '@apollo/client/link/error';

@Injectable({ providedIn: 'root' })
export class GraphqlMainService {
	constructor(private errorStore: ErrorStoreService, private apollo: Apollo, private httpLink: HttpLink, private ngxLogger: NGXLogger) {
		this.initialize();
	}

	private initialize(): void {
		this.createNamedApolloClient(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT);
		this.createNamedApolloClient(APOLLO_CLIENT_NAME.COMPONENT_MANAGEMENT);
		this.createNamedApolloClient(APOLLO_CLIENT_NAME.MASTER_DATA_MANAGEMENT);
		this.createNamedApolloClient(APOLLO_CLIENT_NAME.B2B_ADAPTER);
	}

	private createNamedApolloClient(name: APOLLO_CLIENT_NAME) {
		const uri = this.getUriForNamedApolloClient(name);
		this.apollo.createNamed(name, {
			link: ApolloLink.from([this.errorLink(), this.httpLink.create({ uri })]),
			cache: new InMemoryCache(),
		});
	}

	errorLink() {
		return onError(({ operation, graphQLErrors, networkError }) => {
			graphQLErrors && this.handleGraphQlErrors(operation, graphQLErrors);
			networkError && this.handleNetworkErrors(networkError);
		});
	}

	private getUriForNamedApolloClient(name: APOLLO_CLIENT_NAME, URL_MAP = environment.backend.graphql): string {
		const uri = URL_MAP[name];
		if (!uri) {
			this.ngxLogger.error(`NO GRAPHQL ENDPOINT DEFINED OR FOUND FOR ${name}.`);
		}
		return uri;
	}

	private handleGraphQlErrors(operation: Operation, graphQLErrors: GraphQLErrors): void {
		const { operationName } = operation;
		// If error is listed in the env, the app won't display a global error modal. Instead the error should be displayed on the page, e.g. with the resourceStateDirective.
		if (environment.config.error.disableErrorModalForGraphqlOperation.includes(operationName)) {
			return;
		}
		this.errorStore.setGraphQLError(graphQLErrors);
	}

	private handleNetworkErrors(networkError: NetworkError | string) {
		const errorIfUserIsLoggedOut = 'No current user';
		if ((networkError as string) === errorIfUserIsLoggedOut) {
			this.errorStore.setNetworkError(new HttpErrorResponse({ status: 401 }));
		} else {
			const error: HttpErrorResponse = networkError as HttpErrorResponse;
			this.errorStore.setNetworkError(error);
		}
		this.ngxLogger.error(`[GRAPHQL NETWORK ERROR]`, networkError);
	}
}

import { Component, ElementRef, Input } from '@angular/core';

import { CommonModule } from '@angular/common';
import { ComplianceContent } from '@core/graphql/generated-types';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { GroupuiEmitClickOutsidePopoverDirective } from '@core/directives/group-ui/group-ui-emit-click-outside-popover.directive';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from '@core/i18n/transloco-root.module';
import { TranslocoTestingModule } from '@ngneat/transloco';
import { environment } from '@environments/environment';

@Component({
	selector: 'btr-home-footer',
	standalone: true,
	imports: [CommonModule, GroupUiModule, TranslocoRootModule, TranslocoTestingModule, HttpClientModule, GroupuiEmitClickOutsidePopoverDirective],
	templateUrl: './home-footer.component.html',
})
export class HomeFooterComponent {
	@Input() complianceContent!: ComplianceContent | null;
	supportEmail = `mailto:${environment.config.contact.email}`;
	popover = document.getElementById('groupui-popover');
	isContactPopoverVisible = false;
	isPrivacyPopoverVisible = false;

	constructor(private elementRef: ElementRef) {}

	toggleContactGroupuiPopover() {
		const popovers = document.querySelectorAll('groupui-popover');
		if (popovers.length > 1) {
			// eslint-disable-next-line prefer-destructuring
			this.isContactPopoverVisible = !this.isContactPopoverVisible;
		}
	}
	togglePrivacyGroupuiPopover() {
		const popovers = document.querySelectorAll('groupui-popover');
		if (popovers.length > 1) {
			// eslint-disable-next-line prefer-destructuring
			this.isPrivacyPopoverVisible = !this.isPrivacyPopoverVisible;
		}
	}
}

import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { filter, switchMap, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { RouteData } from '@environments/environment.interface';
import { Title } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root',
})
export class PageTitleService {
	constructor(private transService: TranslocoService, private titleService: Title, private router: Router, private logger: NGXLogger) {}

	enableBrowserTabTitleUpdate$() {
		return this.router.events.pipe(
			filter((evt) => evt instanceof ActivationEnd),
			switchMap((evt) => {
				const data: RouteData = this.getRouteDataFormSnapshot((evt as ActivationEnd).snapshot);
				const translateKey = data?.title ? 'APP.NAVIGATION.LABEL#' + data.title : 'APP.NAME';
				return this.transService.selectTranslate(translateKey);
			}),
			tap((title) => {
				const CTR = this.transService.translate('APP.NAME_ABBR');
				this.logger.trace(`Update Browser Tab Title: ${title}`, this);
				this.titleService.setTitle(`${CTR}: ${title}`);
			}),
		);
	}

	getRouteDataFormSnapshot(snapshot: ActivatedRouteSnapshot): RouteData {
		try {
			if (snapshot.firstChild) {
				return this.getRouteDataFormSnapshot(snapshot?.firstChild);
			}
			return snapshot.data;
		} catch (error) {
			return snapshot.data;
		}
	}
}

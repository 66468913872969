import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, switchMap } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { Injectable } from '@angular/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private auth: AuthService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return from(this.auth.getCurrentAccessToken()).pipe(
			switchMap((token) => {
				if (request.url.includes('/assets/i18n')) {
					// When loading the assests e.g. i18n there should not be an token attached
					return next.handle(request);
				} else {
					const modifiedRequest = request.clone({
						headers: request.headers.set('authorization', `Bearer ${token}`),
					});
					return next.handle(modifiedRequest);
				}
			}),
		);
	}
}

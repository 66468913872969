import { PARAM_NAME, PagePaths } from './core/enum/page-paths.enum';
import { RouterModule, Routes } from '@angular/router';
import { canActivateBasedOnRoles, getRouteDataFromNavItem, removeStartingSlash } from '@core/utils/navigation.utils';

import { HomeComponent } from './home/home.component';
import { MASTER_DATA_ACTIONS } from './core/enum/master-data.enum';
import { NAVIGATION_ITEM_ID } from '@environments/configs/navigationConfig';
import { NgModule } from '@angular/core';
import { PAGE_PERMISSION } from './core/enum/page-permissions.enum';
import { PageNotFoundComponent } from '@shared/page-not-found/page-not-found.component';
import { authGuard } from '@core/guards/auth.guard';

export const routes: Routes = [
	// The following redirect is a required workaround for routing in the intranet. (Original Jira Issue BTR-1626)
	// Redirection from file index.html to the root page is required because of the redirect which happens in the AWS ALB redirecting rule.
	// The only possible way is to redirect always from the index.html file which is than part of the URL.
	// When it's not redirected angular will forward the request to the default instead of a specific route which will result in 404 page.
	{ path: 'index.html', redirectTo: '', pathMatch: 'full' },
	{
		path: '',
		component: HomeComponent,
		canActivate: [authGuard],
		data: { title: 'HOME' },
		children: [
			{
				path: '',
				loadComponent: () => import('./home/pages/home-page/home-page.component').then((m) => m.HomePageComponent),
				data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.HOME) },
			},

			// CONTRACT_MANAGEMENT
			{
				path: removeStartingSlash(PagePaths.CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID),
				data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID) },
				children: [
					{
						path: '',
						loadComponent: () =>
							import('./features/contract-management/pages/contract-list-page/contract-list-page.component').then(
								(m) => m.ContractListPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_ALL_CONTRACTS_BY_PARTNER_ID) },
					},
					{
						path: 'new',
						loadComponent: () =>
							import('./features/contract-management/pages/contract-new-page/contract-new-page.component').then(
								(m) => m.ContractNewPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_NEW) },
					},
					{
						path: `:${PARAM_NAME.CONTRACT_ID}`,
						loadComponent: () =>
							import('./features/contract-management/pages/contract-detail-page/contract-detail-page.component').then(
								(m) => m.ContractDetailPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_DETAIL) },
					},
				],
			},
			{
				path: removeStartingSlash(PagePaths.CONTRACT_MANAGEMENT_COMPONENT_READ_PERMISSION),
				loadComponent: () =>
					import('./features/admin/pages/component-read-permission-page/component-read-permission-page.component').then(
						(c) => c.ComponentReadPermissionPageComponent,
					),
				canActivate: [canActivateBasedOnRoles],
				data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_COMPONENT_READ_PERMISSION) },
			},
			{
				path: removeStartingSlash(PagePaths.CONTRACT_MANAGEMENT_COMPONENT_WRITE_PERMISSION),
				loadComponent: () =>
					import('./features/admin/pages/component-write-permission-page/component-write-permission-page.component').then(
						(c) => c.ComponentWritePermissionPageComponent,
					),
				canActivate: [canActivateBasedOnRoles],
				data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.CONTRACT_MANAGEMENT_COMPONENT_WRITE_PERMISSION) },
			},
			// COMPONENTS

			{
				path: removeStartingSlash(PagePaths.COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID),
				canActivate: [canActivateBasedOnRoles],
				data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID) },
				children: [
					{
						path: '',
						loadComponent: () =>
							import('./features/component-management/pages/component-list-page/component-list-page.component').then(
								(c) => c.ComponentListPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_ALL_COMPONENTS_BY_CONTRACT_ID) },
					},
					{
						path: 'update',
						loadComponent: () =>
							import('./features/component-management/pages/component-update-page/component-update-page.component').then(
								(m) => m.ComponentUpdatePageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_UPDATE) },
					},
					{
						path: 'new',
						loadComponent: () =>
							import('./features/component-management/pages/component-new-page/component-new-page.component').then(
								(c) => c.ComponentNewPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_NEW) },
					},
					{
						path: `:${PARAM_NAME.COMPONENT_ID}`,
						loadComponent: () =>
							import('./features/component-management/pages/component-detail-page/component-detail-page.component').then(
								(m) => m.ComponentDetailPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.COMPONENT_MANAGEMENT_DETAIL) },
					},
				],
			},

			// PART TYPE MANAGEMENT
			{
				path: removeStartingSlash(PagePaths.PART_TYPE_MANAGEMENT_ALL_PART_TYPES),
				loadComponent: () =>
					import('./features/admin/pages/part-type-management-page/part-type-management-page.component').then(
						(c) => c.PartTypeManagementPageComponent,
					),
				canActivate: [canActivateBasedOnRoles],
				data: {
					...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_ALL_PART_TYPES),
				},
			},
			{
				path: removeStartingSlash(PagePaths.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER),
				loadComponent: () =>
					import('./features/admin/pages/partner-permission-page/partner-permission-page.component').then((c) => c.PartnerPermissionPageComponent),
				canActivate: [canActivateBasedOnRoles],
				data: {
					...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER),
					permission: {
						[MASTER_DATA_ACTIONS.MANAGE_ASSIGNED_PARTNER_GROUP]: false,
						[MASTER_DATA_ACTIONS.MANAGE_PART_TYPE_READ_PERMISSION_MANUALLY]: true,
						[PAGE_PERMISSION.READ_MANAGE_PART_TYPE_READ_PERMISSION_PARTNER_GROUP]: false,
						[PAGE_PERMISSION.READ_MANAGE_PART_TYPE_READ_PERMISSION_PART_TYPE]: true,
					},
				},
			},
			{
				path: removeStartingSlash(PagePaths.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER_GROUP),
				loadComponent: () => import('./features/admin/pages/partner-group-page/partner-group-page.component').then((c) => c.PartnerGroupPageComponent),
				canActivate: [canActivateBasedOnRoles],
				data: {
					...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PART_TYPE_MANAGEMENT_READ_PERMISSION_FOR_PARTNER_GROUP),
					permission: {
						[MASTER_DATA_ACTIONS.EDIT_PARTNER_GROUP_PART_TYPE_READ_PERMISSION]: true,
						[MASTER_DATA_ACTIONS.CREATE_PARTNER_GROUP]: false,
						[MASTER_DATA_ACTIONS.DELETE_PARTNER_GROUP]: false,
						[PAGE_PERMISSION.READ_ALL_PARTNER_GROUP_PART_TYPE_READ_PERMISSION]: true,
					},
				},
			},
			// PARTNER MANAGEMENT
			{
				path: removeStartingSlash(PagePaths.PARTNER_MANAGEMENT_ALL_PARTNERS),
				loadComponent: () =>
					import('./features/admin/pages/partner-relation-page/partner-relation-page.component').then((c) => c.PartnerRelationPageComponent),
				canActivate: [canActivateBasedOnRoles],
				data: {
					...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_ALL_PARTNERS),
					permission: {
						[PAGE_PERMISSION.READ_ALL_PARTNER_PERMITTED_CUSTOMER_RELATION]: false,
						[MASTER_DATA_ACTIONS.CREATE_PARTNER]: true,
						[MASTER_DATA_ACTIONS.DELETE_PARTNER]: true,
						[MASTER_DATA_ACTIONS.EDIT_PERMITTED_CUSTOMER]: false,
					},
				},
			},
			{
				path: removeStartingSlash(PagePaths.PARTNER_MANAGEMENT_MANAGE_ASSIGNED_PARTNER_GROUPS),
				loadComponent: () =>
					import('./features/admin/pages/partner-relation-page/partner-relation-page.component').then((c) => c.PartnerRelationPageComponent),
				canActivate: [canActivateBasedOnRoles],
				data: {
					...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_MANAGE_ASSIGNED_PARTNER_GROUPS),
					permission: {
						[PAGE_PERMISSION.READ_ALL_PARTNER_PERMITTED_CUSTOMER_RELATION]: true,
						[MASTER_DATA_ACTIONS.CREATE_PARTNER]: false,
						[MASTER_DATA_ACTIONS.DELETE_PARTNER]: false,
						[MASTER_DATA_ACTIONS.EDIT_PERMITTED_CUSTOMER]: true,
					},
				},
			},
			{
				path: removeStartingSlash(PagePaths.PARTNER_MANAGEMENT_PERMITTED_PARTNER_RELATION),
				loadComponent: () =>
					import('./features/admin/pages/partner-permission-page/partner-permission-page.component').then((c) => c.PartnerPermissionPageComponent),
				canActivate: [canActivateBasedOnRoles],
				data: {
					...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PARTNER_MANAGEMENT_PERMITTED_PARTNER_RELATION),
					permission: {
						[MASTER_DATA_ACTIONS.MANAGE_ASSIGNED_PARTNER_GROUP]: true,
						[MASTER_DATA_ACTIONS.MANAGE_PART_TYPE_READ_PERMISSION_MANUALLY]: false,
						[PAGE_PERMISSION.READ_MANAGE_PART_TYPE_READ_PERMISSION_PARTNER_GROUP]: true,
						[PAGE_PERMISSION.READ_MANAGE_PART_TYPE_READ_PERMISSION_PART_TYPE]: false,
					},
				},
			},
			// PARTNER GROUP MANAGEMENT
			{
				path: removeStartingSlash(PagePaths.PARTNER_GROUP_MANAGEMENT_ALL_PARTNER_GROUPS),
				loadComponent: () => import('./features/admin/pages/partner-group-page/partner-group-page.component').then((c) => c.PartnerGroupPageComponent),
				canActivate: [canActivateBasedOnRoles],
				data: {
					...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PARTNER_GROUP_MANAGEMENT_ALL_PARTNER_GROUPS),
					permission: {
						[MASTER_DATA_ACTIONS.EDIT_PARTNER_GROUP_PART_TYPE_READ_PERMISSION]: false,
						[MASTER_DATA_ACTIONS.CREATE_PARTNER_GROUP]: true,
						[MASTER_DATA_ACTIONS.DELETE_PARTNER_GROUP]: true,
						[PAGE_PERMISSION.READ_ALL_PARTNER_GROUP_PART_TYPE_READ_PERMISSION]: false,
					},
				},
			},
			// PRODUCT PASSES
			{
				path: removeStartingSlash(PagePaths.PRODUCT_PASS_ALL_PRODUCT_PASSES),
				data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASSES) },
				children: [
					{
						path: '',
						loadComponent: () =>
							import('./features/product-pass/pages/product-pass-list-page/product-pass-list-page.component').then(
								(c) => c.ProductPassListPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASSES) },
					},
					{
						path: 'new',
						loadComponent: () =>
							import('./features/product-pass/pages/product-pass-new-page/product-pass-new-page.component').then(
								(c) => c.ProductPassNewPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PRODUCT_PASS_NEW) },
					},
					{
						path: `:${PARAM_NAME.PRODUCT_PASS_ID}`,
						loadComponent: () =>
							import('./features/product-pass/pages/product-pass-detail-page/product-pass-detail-page.component').then(
								(m) => m.ProductPassDetailPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PRODUCT_PASS_DETAIL) },
					},
				],
			},
			// PRODUCT PASS TEMPLATE
			{
				path: removeStartingSlash(PagePaths.PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES),
				data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES) },
				children: [
					{
						path: '',
						loadComponent: () =>
							import('./features/product-pass/pages/product-pass-template-list-page/product-pass-template-list-page.component').then(
								(c) => c.ProductPassTemplateListPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PRODUCT_PASS_ALL_PRODUCT_PASS_TEMPLATES) },
					},
					{
						path: 'new',
						loadComponent: () =>
							import('./features/product-pass/pages/product-pass-template-new-page/product-pass-template-new-page.component').then(
								(c) => c.ProductPassTemplateNewPageComponent,
							),
						canActivate: [canActivateBasedOnRoles],
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PRODUCT_PASS_TEMPLATE_NEW) },
					},
					{
						path: `:${PARAM_NAME.PRODUCT_PASS_TEMPLATE_ID}`,
						loadComponent: () =>
							import('./features/product-pass/pages/product-pass-template-detail-page/product-pass-template-detail-page.component').then(
								(m) => m.ProductPassTemplateDetailPageComponent,
							),
						canActivate: [canActivateBasedOnRoles], 
						data: { ...getRouteDataFromNavItem(NAVIGATION_ITEM_ID.PRODUCT_PASS_TEMPLATE_DETAIL) },
					},
				],
			},
		],
	},
	{
		path: '**',
		component: PageNotFoundComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
	selector: '[btrFocusOnArrowKey]',
	standalone: true,
})
export class FocusOnArrowKeyDirective {
	private readonly DEFAULT_SELECTOR = 'groupui-button';
	@HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
		const buttons = Array.from(this.elRef.nativeElement.querySelectorAll(this.btrFocusOnArrowKey || this.DEFAULT_SELECTOR)) as HTMLInputElement[];
		const currentFocusIdx = buttons.findIndex((button) => button === document.activeElement);
		if (event.key === 'ArrowDown') {
			const nextIndex = (currentFocusIdx + 1) % buttons.length; // Calculate index of next button
			buttons[nextIndex].focus(); // Focus next button
			event.preventDefault();
		} else if (event.key === 'ArrowUp') {
			const prevIndex = ((currentFocusIdx === -1 ? 0 : currentFocusIdx) - 1 + buttons.length) % buttons.length; // Calculate index of previous button
			buttons[prevIndex].focus(); // Focus previous button
			event.preventDefault();
		}
	}
	@Input() btrFocusOnArrowKey!: string;
	constructor(private elRef: ElementRef) {}
}

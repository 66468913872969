<div *transloco="let t" btrFocusOnArrowKey>
	<div class="padding-top-1rem padding-left-1rem padding-right-1rem">
		<groupui-input
			[placeholder]="t('SHARED.PLACEHOLDER.SEARCH_TIME_ZONE')"
			[value]="searchValue"
			(groupuiChange)="onSearchTimezone($event)"
			btrGroupUiEmitChangeOnInput>
			<span slot="label">{{ t('SHARED.LABEL.TIMEZONE') }}</span>
		</groupui-input>
	</div>

	<div class="overflow-y-auto max-height-50vh" *ngIf="timezoneSelected$ | async as timezoneSelected">
		<div class="padding-left-1rem padding-right-1rem">
			<groupui-select-option (click)="onSetTimezone(timezoneBrowser)">
				<div class="display-flex align-items-center justify-content-space-between flex-1">
					<div class="display-flex gap-groupui-spacing-2">
						<groupui-text class="font-weight-bold">{{ timezoneBrowser.name }}</groupui-text>
						<groupui-text
							><small class="color-grey-medium">{{ t('SHARED.LABEL.BROWSER') }}</small></groupui-text
						>
					</div>
					<groupui-text class="min-width-80px">{{ timezoneBrowser.utcOffset }} </groupui-text>
				</div>
			</groupui-select-option>
			<groupui-select-option (click)="onSetTimezone(timezoneUTC)">
				<div class="display-flex align-items-center justify-content-space-between flex-1">
					<div class="display-flex gap-groupui-spacing-2">
						<groupui-text class="font-weight-bold">{{ timezoneUTC.name }} </groupui-text>
						<groupui-text
							><small class="color-grey-medium">{{ timezoneUTC.abbreviation }}</small></groupui-text
						>
					</div>
					<groupui-text class="min-width-80px">{{ timezoneUTC.utcOffset }} </groupui-text>
				</div>
			</groupui-select-option>
			<groupui-divider></groupui-divider>
		</div>

		<ng-container *ngFor="let continent of timezonesByContinent; trackBy: trackByName">
			<groupui-item size="s" class="padding-left-1rem padding-right-1rem">
				<groupui-text class="color-grey-medium">{{ continent.name }}</groupui-text>
				<groupui-select-option (click)="onSetTimezone(item)" *ngFor="let item of continent.timezones; trackBy: trackByName">
					<div class="display-flex align-items-center justify-content-space-between flex-1">
						<div class="display-flex flex-wrap-wrap gap-groupui-spacing-2">
							<groupui-text class="font-weight-bold">{{ item.name }} </groupui-text>
							<groupui-text
								><small class="color-grey-medium">{{ item.countryName }}, {{ item.abbreviation }}</small></groupui-text
							>
						</div>
						<groupui-text class="min-width-80px">{{ item.utcOffset }} </groupui-text>
					</div>
				</groupui-select-option>
			</groupui-item>
		</ng-container>
	</div>
</div>

import { Component, OnInit } from '@angular/core';
import { ErrorNotification, ErrorStoreService } from '@core/services/error-handling/error-store.service';
import { LoadingOptions, LoadingService } from './core/services/loading/loading.service';
import { NotificationCard, NotificationCardType } from '@shared/notification-card/notification-card.component';
import { Observable, merge, tap } from 'rxjs';

import { AuthService } from '@core/services/auth.service';
import { ErrorType } from './core/enum/error-type.enum';
import { FADE_ANIMATION } from './app.animation';
import { NGXLogger } from 'ngx-logger';
import { PageTitleService } from '@core/services';
import { SwManagerService } from '@core/services/app/sw-manager.service';
import { environment } from '@environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: FADE_ANIMATION,
})
export class AppComponent implements OnInit {
	errorNotificationCard: NotificationCard = {
		isOpen: false,
		type: NotificationCardType.ERROR,
		title: 'SHARED.ERROR.UNEXPECTED_ERROR_TITLE',
		contentText: '',
		onClose: this.closeError.bind(this),
	};
	authenticationFailedNotificationCard: NotificationCard = {
		isOpen: false,
		type: NotificationCardType.ERROR,
		title: '',
		contentText: '',
		closeAble: false,
		onClose: this.closeError.bind(this),
	};
	loading$!: Observable<LoadingOptions>;

	showUpdateAppModal = false;

	/**
	 * @description Rxjs stream handled by an async pipe.
	 */
	componentObservables$!: Observable<unknown>;

	constructor(
		private logger: NGXLogger,
		private pageTitleService: PageTitleService,
		private errorStore: ErrorStoreService,
		private auth: AuthService,
		private authService: AuthService,
		private swManager: SwManagerService,
		private loadingService: LoadingService,
	) {
		this.setLogLevel();
	}

	ngOnInit(): void {
		this.logInfos();
		this.swManager.activateSWManager();
		this.loading$ = this.loadingService.getLoadingState$();
		this.initialize();
	}

	/**
	 * @description Entry point for component. Calling this method will always reset and initialize entire component.
	 */
	private initialize() {
		this.componentObservables$ = merge(
			this.errorStore.errorState$.pipe(tap((res) => this.handleError(res))),
			this.auth.$isLoggedIn.pipe(tap((isLoggedIn: boolean) => !isLoggedIn && this.auth.redirectToLogin())),
			this.auth.logoutAfterUserInactivity$(),
			this.auth.saveUrlForDevAutomaticRefresh$(),
			this.pageTitleService.enableBrowserTabTitleUpdate$(),
			this.swManager.getUpdateApp$().pipe(tap(() => (this.showUpdateAppModal = true))),
		);
	}

	private handleError(res: ErrorNotification) {
		if (res.type === ErrorType.AUTHORIZATION_FAILED) {
			this.authenticationFailedNotificationCard.isOpen = true;
			this.authenticationFailedNotificationCard.contentText = res.message;
			this.authenticationFailedNotificationCard.optionalMessage = res.optionalMessage;
			this.authenticationFailedNotificationCard.title = res.title;
			this.authenticationFailedNotificationCard.primaryButton = { label: 'SHARED.BUTTON.LOGOUT' };
		} else if (res.title) {
			this.errorNotificationCard.contentText = res.message;
			this.errorNotificationCard.optionalMessage = res.optionalMessage;
			this.errorNotificationCard.title = res.title;
			this.errorNotificationCard.isOpen = true;
			this.errorStore.closeError();
		}
	}

	onLogout() {
		this.authService.logout();
	}

	private logInfos() {
		this.logger.trace(`Base Href: ${environment.mainUrlBaseHref}`, this);
	}

	private setLogLevel() {
		this.logger.updateConfig({ level: environment.logging.level });
	}

	private closeError(): void {
		this.errorStore.closeError();
	}
}

import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionReadyEvent } from '@angular/service-worker';
import { concat, filter, first, interval } from 'rxjs';

import { NGXLogger } from 'ngx-logger';
import { SERVICE_WORKER_EVENT } from '@core/enum/service-worker.enum';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class SwManagerService {
	constructor(private swUpdate: SwUpdate, private appRef: ApplicationRef, private logger: NGXLogger, private transloco: TranslocoService) {}

	getUpdateApp$() {
		return this.swUpdate.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === SERVICE_WORKER_EVENT.VERSION_READY));
	}

	activateSWManager() {
		this.dispatchServiceWorkerEvents();
		this.checkPeriodicallyForUpdate();
		this.handleUnrecoverableState();
	}

	dispatchServiceWorkerEvents() {
		this.swUpdate.versionUpdates.subscribe((evt) => {
			switch (evt.type) {
				case SERVICE_WORKER_EVENT.VERSION_DETECTED:
					this.logger.info('[ServiceWorkerManager] Downloading Version Detected', this);
					break;
				case SERVICE_WORKER_EVENT.VERSION_READY:
					this.logger.info(`[ServiceWorkerManager] Current app version: ${evt.currentVersion.hash}`, this);
					this.logger.info(`[ServiceWorkerManager] New app version ready for use: ${evt.latestVersion.hash}`, this);
					break;
				case SERVICE_WORKER_EVENT.VERSION_INSTALLATION_FAILED:
					this.logger.error(`[ServiceWorkerManager] Failed to install app version '${evt.version.hash}': ${evt.error}`, this);
					break;
			}
		});
	}

	handleUnrecoverableState() {
		this.swUpdate.unrecoverable.subscribe((event: UnrecoverableStateEvent) => {
			this.logger.fatal(`[ServiceWorkerManager] An error occurred that we cannot recover from: ${event.reason}`, this);
		});
	}

	checkPeriodicallyForUpdate(intervalMs = environment.config.serviceWorker.refreshTimerMS) {
		const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
		const timerInterval$ = interval(intervalMs);
		const whenTimerAndStable$ = concat(appIsStable$, timerInterval$);

		whenTimerAndStable$.subscribe(() => this.performCheckAfterStable());
	}

	async performCheckAfterStable() {
		try {
			const updateFound = await this.swUpdate.checkForUpdate();
			if (updateFound) {
				this.logger.info('[ServiceWorkerManager] A new version is available', this);
			}
			!updateFound && this.logger.info('[ServiceWorkerManager] Already on the latest version.', this);
		} catch (err) {
			this.logger.error(`[ServiceWorkerManager] Failed to check for updates:`, err, this);
		}
	}

	requestUserForUpdate(evt: VersionReadyEvent): boolean {
		this.logger.debug('[ServiceWorkerManager] ', evt);
		const confirmationRequestText = this.transloco.translate('APP.SERVICE_WORKER.MODAL_TITLE#UPDATE_REQUEST');
		const result = confirm(confirmationRequestText);
		result && this.logger.info('[ServiceWorkerManager] User Confirmed update', this);
		!result && this.logger.info('[ServiceWorkerManager] User rejected update', this);
		return result;
	}
}

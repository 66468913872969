import { Component, Input } from '@angular/core';

import { AuthService } from '@core/services/auth.service';
import { CommonModule } from '@angular/common';
import { ComplianceContent } from '@core/graphql/generated-types';
import { DateTimeService } from '@core/services/date-time/date-time.service';
import { FormsModule } from '@angular/forms';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { PagePaths } from '@core/enum/page-paths.enum';
import { RouterModule } from '@angular/router';
import { TimezoneSelectComponent } from '@shared/timezone-select/timezone-select.component';
import { TranslocoRootModule } from '@core/i18n/transloco-root.module';
import { TranslocoTestingModule } from '@ngneat/transloco';
import { environment } from '@environments/environment';

@Component({
	selector: 'btr-home-header',
	templateUrl: './home-header.component.html',
	styleUrls: ['./home-header.component.scss'],
	standalone: true,
	imports: [CommonModule, RouterModule, GroupUiModule, FormsModule, TimezoneSelectComponent, TranslocoRootModule, TranslocoTestingModule],
})
export class HomeHeaderComponent {
	readonly PAGE_PATHS = PagePaths;
	@Input() complianceContent!: ComplianceContent | null;
	@Input() version!: string;
	supportEmail = environment.config.contact.email;
	timezoneSelected$ = this.dateTimeService.getSelectedTimezone$();
	constructor(private auth: AuthService, private dateTimeService: DateTimeService) {}

	public onLogout() {
		this.auth.logout();
	}
}

<ng-container *ngIf="componentObservable$ | async"></ng-container>
<!-- Header -->
<div class="display-flex flex-direction-column height-100vh width-100vw">
	<btr-home-header class="z-index-4" [complianceContent]="complianceContent" [version]="version"></btr-home-header>
	<groupui-notification visible visible dismissible="false" variant="primary" severity="warning" *ngIf="showInactivityLogoutWarning" @fade>
		<groupui-grid marginType="custom" margin="0">
			<groupui-grid-row>
				<groupui-grid-col offsetXxl="2" xxl="8" xl="12" l="12">
					<div class="display-flex align-items-center gap-groupui-spacing-3">
						<groupui-icon name="warning-filled-24" class="color-white"></groupui-icon>
						<groupui-text class="color-white">{{
							'APP.NOTIFICATION_MESSAGE#INACTIVITY_LOGOUT_PENDING' | transloco : { $1: showInactivityLogoutWarning }
						}}</groupui-text>
					</div>
				</groupui-grid-col>
			</groupui-grid-row>
		</groupui-grid>
		<div
			class="logout-progress-bar"
			[class.logout-progress-bar-active]="showInactivityLogoutProgressBar"
			[style.transition-duration]="inactivityLogoutProgressAnimationDuration"></div>
	</groupui-notification>
	<!-- Content -->
	<div class="content-container">
		<div class="content-area">
			<groupui-grid marginType="custom" margin="0">
				<groupui-grid-row>
					<groupui-grid-col offsetXxl="2" xxl="8" xl="12" l="12">
						<router-outlet></router-outlet>
					</groupui-grid-col>
				</groupui-grid-row>
			</groupui-grid>
		</div>
		<!-- Footer -->
		<btr-home-footer [complianceContent]="complianceContent"></btr-home-footer>
	</div>
</div>

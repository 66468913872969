import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { NAVIGATION_ITEM_ID, NAVIGATION_ITEM_MAP } from '@environments/configs/navigationConfig';
import { NavItem, RouteData } from '@environments/environment.interface';

import { PagePaths } from '../enum/page-paths.enum';
import { UserService } from '../services/user/user.service';
import { inject } from '@angular/core';

/**
 * @description Returns RouteData Object for as specific NavigationItemId, which is used for the route.data property to pass configuration to each page
 */
export function getRouteDataFromNavItem(id: NAVIGATION_ITEM_ID, navMap = NAVIGATION_ITEM_MAP): RouteData | null {
	const item = getNavItemById(id, navMap);
	if (item) {
		const targetItem: RouteData | null = {
			id,
			title: item.id,
			canNavigateToRoles: item.canNavigateToRoles,
		};
		return targetItem;
	}
	console.error('NAVIGATION_ITEM NOT FOUND', id);
	return null;
}

/**
 * @description Returns RouteData Object for as specific NavigationItemId
 */
export function getNavItemById(id: NAVIGATION_ITEM_ID, navMap = NAVIGATION_ITEM_MAP): NavItem | null {
	const targetItem: NavItem = navMap[id];
	return targetItem;
}

/**
 * @description Removes starting `/` from a string. Required to use pagePaths as path definition for angular routes
 */
export function removeStartingSlash(path: PagePaths): string {
	return path.charAt(0) === '/' ? path.slice(1) : path;
}

/**
 * @description Used as Route Guard to determine route access based on user roles
 */
export function canActivateBasedOnRoles(route: ActivatedRouteSnapshot): CanActivateFn {
	return () => inject(UserService).canNavigate(route.data);
}

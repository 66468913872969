import { BehaviorSubject } from 'rxjs';
import { ErrorType } from '@core/enum/error-type.enum';
import { GraphQLErrors } from '@apollo/client/errors';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { globalConfig } from '@environments/configs/globalConfig';
import { last } from 'lodash';

export interface ErrorNotification {
	type: ErrorType;
	message: string;
	optionalMessage?: string;
	title: string;
	preventModalClosing?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ErrorStoreService {
	private errors = new BehaviorSubject<ErrorNotification>({
		type: ErrorType.NONE,
		message: '',
		title: '',
	});

	errorState$ = this.errors.asObservable();

	getError() {
		return this.errors.value;
	}
	closeError(): void {
		this.errors.next({ type: ErrorType.NONE, message: '', title: '' });
	}

	setUnauthorizedError(): void {
		this.errors.next({
			type: ErrorType.RESTRICTED_ACCESS,
			message: 'SHARED.ERROR.AUTHORIZATION_MESSAGE',
			title: 'SHARED.ERROR.AUTHORIZATION_TITLE',
		});
	}

	setNetworkError(error: HttpErrorResponse): void {
		const UNAUTHORIZED = 401;
		if (error.status === UNAUTHORIZED) {
			this.errors.next({
				type: ErrorType.AUTHORIZATION_FAILED,
				title: 'SHARED.ERROR.401_TITLE',
				message: 'SHARED.ERROR.401_MESSAGE',
				preventModalClosing: true,
			});
		}
	}

	setGraphQLError(error: GraphQLErrors): void {
		let type = ErrorType.DEFAULT;
		let message = '';
		let title = '';

		const separatedError = error[0].message.split(globalConfig.error.separator);
		let optionalMessage = last(separatedError);

		switch (separatedError[0] as unknown as ErrorType) {
			case ErrorType.BAD_REQUEST: {
				type = ErrorType.BAD_REQUEST;
				message = 'SHARED.ERROR.BAD_REQUEST_MESSAGE';
				title = 'SHARED.ERROR.BAD_REQUEST_TITLE';
				break;
			}
			case ErrorType.AUTHORIZATION_FAILED: {
				type = ErrorType.AUTHORIZATION_FAILED;
				message = 'SHARED.ERROR.ALERT_MESSAGE#AUTHORIZATION_FAILED';
				title = 'SHARED.ERROR.AUTHORIZATION_FAILED_TITLE';
				break;
			}
			default: {
				// default error handling
				optionalMessage = error.map((m) => m.message).join(', ');
				type = ErrorType.DEFAULT;
				message = 'SHARED.ERROR.UNEXPECTED_ERROR_MESSAGE';
				title = 'SHARED.ERROR.UNEXPECTED_ERROR_TITLE';
				break;
			}
		}
		this.errors.next({ type, message, optionalMessage, title });
	}
}

import {
	Contract,
	ContractFullDetails,
	ContractHistory,
	ContractRequest,
	ContractState,
	DeleteContractRequest,
	GetContractsByPartnerIdRequest,
	UpdateContractStateRequest,
	UpdateContractXsdRequest,
} from '@core/graphql/generated-types';
import {
	ContractFullDetailsResponse,
	ContractResponse,
	ContractXsdResponse,
	DeleteContractResponse,
	GetContractsByPartnerIdResponse,
	UpdateContractStateResponse,
} from './contract.interface';
import {
	GQL_MUTATION_DELETE_CONTRACT,
	GQL_MUTATION_REGISTER_CONTRACT,
	GQL_MUTATION_UPDATE_CONTRACT_STATE,
	GQL_MUTATION_UPDATE_CONTRACT_XSD,
	GQL_QUERY_CONTRACTS_BY_PARTNER_ID,
	GQL_QUERY_CONTRACT_BY_PERMISSION,
	GQL_QUERY_CONTRACT_DETAILS,
	GQL_QUERY_CONTRACT_HISTORY_BY_ID,
	GQL_QUERY_CONTRACT_XSD_URL,
} from './contract-gql.constants';
import { Observable, map } from 'rxjs';

import { APOLLO_CLIENT_NAME } from '@core/enum/apollo-client-name.enum';
import { Apollo } from 'apollo-angular';
import { FetchPolicy } from '@apollo/client';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ContractAdapterService {
	constructor(private apollo: Apollo) {}

	allContractsRequest: GetContractsByPartnerIdRequest = {
		ContractStates: [
			ContractState.Draft,
			ContractState.New,
			ContractState.InApproval,
			ContractState.Approved,
			ContractState.Activated,
			ContractState.Deactivated,
			ContractState.Archived,
			ContractState.Rejected,
		],
	};

	getContractsByPartnerId(contractStates?: ContractState[], fetchPolicy: FetchPolicy = 'cache-first'): Observable<ContractFullDetails[]> {
		const contractById: GetContractsByPartnerIdRequest = { ContractStates: contractStates || this.allContractsRequest.ContractStates };
		return this.apollo
			.use(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT)
			.watchQuery<GetContractsByPartnerIdResponse>({
				query: GQL_QUERY_CONTRACTS_BY_PARTNER_ID,
				variables: { input: contractById },
				fetchPolicy,
			})
			.valueChanges.pipe(map((result) => (result.data?.getContractsByPartnerId ? result.data.getContractsByPartnerId : [])));
	}

	registerContract(contract: ContractRequest): Observable<Contract> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT)
			.mutate<ContractResponse>({
				mutation: GQL_MUTATION_REGISTER_CONTRACT,
				variables: { input: contract },
			})
			.pipe(map((result) => result.data?.registerContract as Contract));
	}

	deleteContract(contractId: string): Observable<DeleteContractRequest | null> {
		const body: DeleteContractRequest = {
			ContractId: contractId,
		};

		return this.apollo
			.use(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT)
			.mutate<DeleteContractResponse>({
				mutation: GQL_MUTATION_DELETE_CONTRACT,
				variables: {
					input: body,
				},
				refetchQueries: [
					{
						query: GQL_QUERY_CONTRACTS_BY_PARTNER_ID,
						variables: {
							input: this.allContractsRequest,
						},
					},
				],
			})
			.pipe(map((result) => (result.data && result.data?.deleteContract ? result.data.deleteContract : null)));
	}

	getContractXsdUrl(contractId: string): Observable<string> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT)
			.query<ContractXsdResponse>({
				query: GQL_QUERY_CONTRACT_XSD_URL,
				fetchPolicy: 'no-cache',
				variables: { contractId },
			})
			.pipe(map((result) => result.data.exportContractXsdById.XsdUrl));
	}

	contractHistoryById(contractId: string, fetchPolicy: FetchPolicy): Observable<ContractHistory[]> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT)
			.query<{ contractHistoryById: ContractHistory[] }>({
				query: GQL_QUERY_CONTRACT_HISTORY_BY_ID,
				variables: { contractId },
				fetchPolicy,
			})
			.pipe(map((result) => result.data.contractHistoryById));
	}

	updateContractXSD(contractId: string, fileBase64: string, fileName: string): Observable<unknown> {
		const input: UpdateContractXsdRequest = {
			ContractId: contractId,
			XSDSchema: fileBase64,
			XSDFileName: fileName,
		};
		return this.apollo.use(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT).mutate({
			mutation: GQL_MUTATION_UPDATE_CONTRACT_XSD,
			variables: { input },
		});
	}

	updateContractState(state: ContractState, contractId: string): Observable<unknown> {
		const body: UpdateContractStateRequest = {
			ContractId: contractId,
			State: state,
		};
		return this.apollo.use(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT).mutate<UpdateContractStateResponse | null>({
			mutation: GQL_MUTATION_UPDATE_CONTRACT_STATE,
			variables: {
				input: body,
			},
			refetchQueries: [
				{
					query: GQL_QUERY_CONTRACT_DETAILS,
					variables: {
						contractId: contractId,
					},
				},
				{
					query: GQL_QUERY_CONTRACTS_BY_PARTNER_ID,
					variables: {
						input: this.allContractsRequest,
					},
				},
			],
		});
	}

	getContractFullDetailsById(contractId: string, fetchPolicy: FetchPolicy): Observable<ContractFullDetails> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT)
			.query<ContractFullDetailsResponse>({
				query: GQL_QUERY_CONTRACT_DETAILS,
				variables: { contractId },
				fetchPolicy,
			})
			.pipe(map((result) => result.data.getContractFullDetailsById));
	}

	contractByPermission(fetchPolicy: FetchPolicy): Observable<Contract[]> {
		return this.apollo
			.use(APOLLO_CLIENT_NAME.CONTRACT_MANAGEMENT)
			.query<{ contractByPermission: Contract[] }>({
				query: GQL_QUERY_CONTRACT_BY_PERMISSION,
				fetchPolicy,
			})
			.pipe(map((result) => result.data?.contractByPermission));
	}
}

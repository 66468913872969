import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoAuthEvent, CognitoHubChannel, CognitoTokenFieldName } from '@core/enum/cognito.enum';
import { DecodedJWT, ManageablePartnerIds } from '@core/models/auth.model';
import { Inject, Injectable, Optional } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, debounceTime, filter, fromEvent, interval, map, merge, of, startWith, switchMap, tap, timer } from 'rxjs';

import { AppEnvironment } from '@environments/environment.interface';
import { Duration } from 'luxon';
import { Environments } from '../enum/environments.enum';
import { NGXLogger } from 'ngx-logger';
import { PagePaths } from '@core/enum/page-paths.enum';
import { Roles } from '@core/enum/roles.enum';
import { UserService } from './user/user.service';
import { environment } from '@environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private PAGE_PATHS = PagePaths;
	private loggedIn!: boolean;
	public readonly $isLoggedIn = new Subject<boolean>();
	private readonly indexFile = '/index.html';
	private environment: AppEnvironment;
	constructor(private logger: NGXLogger, private userService: UserService, private router: Router, @Optional() @Inject('env') env: AppEnvironment) {
		this.loggedIn = false;
		this.environment = env || environment;
		if (environment.environment === Environments.LOCAL) {
			this.handleSignIn(this.environment.backend.auth.mockDecodedJWT as DecodedJWT, this.environment.backend.auth.mockDecodedJWT as DecodedJWT);
		} else {
			this.initialize();

			Hub.listen(CognitoHubChannel.AUTH, (data) => {
				if (data.payload.event === CognitoAuthEvent.SIGNIN_FAILURE) {
					this.logger.fatal('Login failed', this);
					this.router.navigateByUrl(PagePaths.HOME);
				} else if (data.payload.event === CognitoAuthEvent.SIGNIN) {
					Auth.currentSession().then((currentSession) =>
						this.handleSignIn(currentSession.getAccessToken().payload as DecodedJWT, currentSession.getIdToken().payload as DecodedJWT),
					);
				} else if (data.payload.event === CognitoAuthEvent.SIGNOUT) {
					this.handleSignOut();
				} else {
					this.logger.trace('Login Auth Event Occurred', data.payload.event, this);
				}
			});
		}
	}
	public isAuthenticated(): boolean {
		if (!this.loggedIn) {
			this.logger.trace(`User not logged in - trying to login`, this);
			this.loginAndGetToken();
		}

		return this.loggedIn;
	}

	public userActivityEvent$(eventName = ['mousemove', 'keydown']): Observable<boolean | Event> {
		const userEvents = eventName.map((evt) => fromEvent(document, evt));
		return merge(...userEvents, of(true)).pipe(debounceTime(this.environment.config.form.debounceTimeMs));
	}

	public logoutAfterUserInactivity$(durationMS = this.environment.config.security.logoutAfterInactivityDurationMS) {
		return this.userActivityEvent$().pipe(
			switchMap(() => timer(durationMS)),
			tap(() => this.logout()),
		);
	}

	public showInactivityLogoutWarning$(
		durationBeforeInactivityWarningMs = this.environment.config.security.durationBeforeInactivityWarningMs,
		durationMS = this.environment.config.security.logoutAfterInactivityDurationMS,
	) {
		const tickMs = 1000;
		return this.userActivityEvent$().pipe(
			switchMap(() =>
				timer(Math.max(durationMS - durationBeforeInactivityWarningMs, 0)).pipe(
					// Make interval start with -1, 0 , 1, ...
					switchMap(() => interval(tickMs).pipe(startWith(-1))),
					map((val) => {
						const remainingTimeMs = durationBeforeInactivityWarningMs - (val + 1) * tickMs;
						return Duration.fromMillis(remainingTimeMs).toFormat('mm:ss');
					}),
					startWith(null),
				),
			),
		);
	}

	public redirectToLogin(): void {
		this.logger.trace(`Login Screen redirect`, this);
		this.loginAndGetToken();
	}

	public logout(): void {
		this.logger.trace(`Logout`, this);
		Auth.signOut();
	}

	public saveUrlForDevAutomaticRefresh$() {
		return this.router.events.pipe(
			filter((val) => val instanceof NavigationEnd),
			tap(() => {
				if ((this.environment.environment as Environments) === Environments.LOCAL) {
					this.savePreviousUrlToLocalStorage(this.environment.config.url.previousURLLocalStorageKeyForLocalDev);
				}
			}),
		);
	}

	private navigateAuthenticatedUser(): void {
		const previousUrl =
			this.loadPreviousUrlFromLocalStorage() || this.loadPreviousUrlFromLocalStorage(this.environment.config.url.previousURLLocalStorageKeyForLocalDev);
		if (previousUrl) {
			const url = new URL(previousUrl);
			if (url.pathname === '/') {
				this.router.navigateByUrl(this.PAGE_PATHS.HOME);
			} else {
				const { pathname } = url;
				// , is require for destructuring
				const [, queryParams] = previousUrl.split('?');
				const targetUrl = queryParams ? `${pathname}?${queryParams}` : `${pathname}`;
				this.router.navigateByUrl(targetUrl);
			}
		} else {
			this.router.navigateByUrl(this.PAGE_PATHS.HOME);
		}
	}

	private savePreviousUrlToLocalStorage(storageKey = this.environment.config.url.previousURLLocalStorageKey) {
		const { href } = window.location;
		if (href) {
			window.localStorage.setItem(storageKey, href);
		}
	}
	private loadPreviousUrlFromLocalStorage(storageKey = this.environment.config.url.previousURLLocalStorageKey) {
		const res = window.localStorage.getItem(storageKey);
		this.removePreviousUrlFromLocalStorage(storageKey);
		return res;
	}

	private removePreviousUrlFromLocalStorage(storageKey = this.environment.config.url.previousURLLocalStorageKey) {
		window.localStorage.removeItem(storageKey);
	}

	private handleSignOut() {
		this.loggedIn = false;
		this.$isLoggedIn.next(false);
		this.logger.trace('Logout Successful', this);
	}

	private handleSignIn(decodedAccessToke: DecodedJWT, decodedIdToken: DecodedJWT): void {
		// Read ID Token and set user info
		this.processAccessToken(decodedAccessToke);
		// Read Access Token and set user permission
		this.processUserName(decodedIdToken);
		// Set Login State
		this.loggedIn = true;
		this.$isLoggedIn.next(this.loggedIn);

		this.logger.trace('Login Successful', this);
		this.navigateAuthenticatedUser();
	}

	private initialize() {
		const config = this.environment.backend.auth.cognito;
		// CDN Distributed content works with origin only
		// Intranet Distribution by ALB only works when index.html redirect happens here
		const targetURL = window.location.origin;
		const targetURLPath =
			window.location.origin.includes(this.environment.config.urlPrefix) || window.location.origin.includes('localhost') ? '' : this.indexFile;
		config.oauth.redirectSignIn = targetURL + targetURLPath;
		config.oauth.redirectSignOut = targetURL + targetURLPath;

		Amplify.configure({
			Auth: config,
		});
	}
	private async loginAndGetToken() {
		if (this.environment.environment !== Environments.LOCAL) {
			if (!/code=([^&]+)/.exec(window.location.search)?.[1]) {
				this.logger.trace('Request to SignIn', this);
				this.savePreviousUrlToLocalStorage();
				await Auth.federatedSignIn();
			}
		}
	}

	private processUserName(idToken: DecodedJWT): void {
		this.userService.setUserId(idToken.preferred_username || idToken['cognito:username']);
	}

	private processAccessToken(accessToken: DecodedJWT): void {
		this.userService.setPartnerId(accessToken.partnerId);
		// Set Roles in accordance to IDP
		const roles = this.mapRoles(accessToken['cognito:groups']);
		this.userService.setRoles(roles);
		// Set additional Permissions.
		const manageablePartnerIds: ManageablePartnerIds = accessToken.manageablePartnerIds ? JSON.parse(accessToken.manageablePartnerIds) : null;
		if (manageablePartnerIds) {
			this.userService.setManageablePartnerIds(manageablePartnerIds);
			//  This might add Roles based on the content of the manageable partner ids (BTR-1718)
			this.userService.updateRolesByManageablePartnerIds();
		}
	}

	private mapRoles(cognitoGroups: string[]): Roles[] {
		let tokenRoles: string[] = [];

		if (!cognitoGroups || cognitoGroups.length == 0) {
			this.logger.trace('Login Cognito Groups available', this);
			return [];
		}

		tokenRoles = cognitoGroups.filter((f: string) => f.includes(CognitoTokenFieldName.PREFIX_ROLE));

		if (!tokenRoles || tokenRoles.length == 0) {
			this.logger.trace('Login No Roles to map', this);
			return [];
		}

		const mappedRoles = tokenRoles.map((role) => this.translateRoleName(role));
		return mappedRoles.filter((r) => r != '') as Roles[];
	}

	private translateRoleName(role: string) {
		this.logger.trace(`Roles: Mapping Server Role: ${role}`);
		const roleName = role.split(':')[1].replaceAll('-', '_');
		const index = Object.values(Roles).indexOf(roleName as Roles);

		if (index >= 0) {
			const key = Object.keys(Roles)[index];
			this.logger.trace(`Roles: to Client Role: ${key}`);
			return key && Roles[key.toString() as keyof typeof Roles];
		} else {
			this.logger.error(`Unknown Role ${roleName}`, this);
			return '';
		}
	}

	async getCurrentAccessToken(): Promise<string> {
		if (this.environment.environment !== Environments.LOCAL) {
			const currentSession = await Auth.currentSession();
			return currentSession.getAccessToken().getJwtToken();
		}
		{
			return '';
		}
	}
}

<ng-container *transloco="let t">
	<groupui-header>
		<groupui-grid marginType="custom" margin="0" class="flex-1">
			<groupui-grid-row>
				<groupui-grid-col offsetXxl="2" xxl="8" l="12">
					<div class="display-flex justify-content-space-between align-items-center">
						<div class="display-flex align-items-center flex-1">
							<a
								class="position-relative display-flex align-items-center text-decoration-none gap-groupui-spacing-3"
								[routerLink]="[PAGE_PATHS.HOME]">
								<div class="position-relative version" [attr.data-version]="'v' + version">
									<groupui-headline class="color-white text-decoration-none show-lager-equal-than-L" heading="h5">
										{{ t('APP.NAME') }}
									</groupui-headline>
									<groupui-headline class="color-white text-decoration-none show-less-than-L" heading="h5">
										{{ t('APP.NAME_ABBR') }}
									</groupui-headline>
								</div>
							</a>
						</div>
						<div class="display-flex align-items-center gap-groupui-spacing-3 min-height-64px">
							<!-- Nav -->
							<groupui-action-area theme="vwag">
								<groupui-action-area-popover-item *ngIf="timezoneSelected$ | async as timezoneSelected" #popRef>
									<!-- User Icon -->
									<span slot="parent" class="display-flex align-items-center gap-groupui-spacing-3">
										<groupui-icon name="clock-32" theme="vwag"></groupui-icon> {{ timezoneSelected.name }}
										<span class="show-lager-equal-than-M">({{ timezoneSelected.utcOffset }})</span>
									</span>
									<!-- Action List -->
									<div slot="content" class="width-450px">
										<btr-timezone-select class="width-100p"></btr-timezone-select>
									</div>
								</groupui-action-area-popover-item>
								<groupui-action-area-popover-item>
									<!-- Info Icon -->
									<span slot="parent"><groupui-icon name="info-32"></groupui-icon></span>

									<!-- Action List -->
									<div slot="content" class="display-flex flex-direction-column min-width-300px width-100p">
										<div>
											<groupui-text size="caption" class="margin-top-1rem padding-left-1rem color-grey">{{
												t('APP.HEADER.CONTACT')
											}}</groupui-text>
											<groupui-link data-test="link-email" target="blank" href="mailto: {{ supportEmail }}">
												<groupui-select-option>
													<div class="display-flex justify-content-space-between align-items-center width-100p">
														<groupui-text class="padding-left-05rem">{{ t('APP.HEADER.EMAIL') }}</groupui-text>
														<groupui-icon class="padding-right-05rem" name="email-24" theme="vwag"></groupui-icon>
													</div>
												</groupui-select-option>
											</groupui-link>
										</div>
										<groupui-divider></groupui-divider>
										<groupui-link target="blank" quiet="true" [href]="complianceContent?.Imprint">
											<groupui-select-option>
												<div class="display-flex justify-content-space-between align-items-center width-100p">
													<groupui-text quiet="true" class="padding-left-05rem">{{ t('APP.HEADER.IMPRINT') }}</groupui-text>
													<groupui-icon
														class="padding-right-05rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-select-option>
										</groupui-link>
										<groupui-divider></groupui-divider>

										<div>
											<groupui-text size="caption" class="margin-top-1rem padding-left-1rem color-grey">{{
												t('APP.HEADER.PRIVACY')
											}}</groupui-text>
											<groupui-link
												data-test="link-brand-employee-policy"
												target="blank"
												quiet="true"
												[href]="complianceContent?.VwBrandEmployeePolicy">
												<groupui-select-option>
													<div class="display-flex justify-content-space-between align-items-center width-100p">
														<groupui-text quiet="true" class="padding-left-05rem">{{
															t('APP.HEADER.BRAND_EMPLOYEE_POLICY')
														}}</groupui-text>
														<groupui-icon
															class="padding-right-05rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-select-option>
											</groupui-link>

											<groupui-link
												data-test="link-business-partner-policy"
												target="blank"
												quiet="true"
												[href]="complianceContent?.BusinessPartnerPolicy">
												<groupui-select-option>
													<div class="display-flex justify-content-space-between align-items-center width-100p">
														<groupui-text quiet="true" class="padding-left-05rem">{{
															t('APP.HEADER.BUSINESS_PARTNER_POLICY')
														}}</groupui-text>
														<groupui-icon
															class="padding-right-05rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-select-option>
											</groupui-link>

											<groupui-link data-test="link-cookie-policy" target="blank" quiet="true" [href]="complianceContent?.CookiePolicy">
												<groupui-select-option>
													<div class="display-flex justify-content-space-between align-items-center width-100p">
														<groupui-text quiet="true" class="padding-left-05rem">{{ t('APP.HEADER.COOKIE') }}</groupui-text>
														<groupui-icon
															class="padding-right-05rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-select-option>
											</groupui-link>

											<groupui-link data-test="link-privacy-policy" target="blank" quiet="true" [href]="complianceContent?.PrivacyPolicy">
												<groupui-select-option>
													<div class="display-flex justify-content-space-between align-items-center width-100p">
														<groupui-text quiet="true" class="padding-left-05rem">{{
															t('APP.HEADER.PRIVACY_POLICY')
														}}</groupui-text>
														<groupui-icon
															class="padding-right-05rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-select-option>
											</groupui-link>

											<groupui-link
												data-test="link-vw-group-employee-policy"
												target="blank"
												quiet="true"
												[href]="complianceContent?.VwGroupEmployeePolicy">
												<groupui-select-option>
													<div class="display-flex justify-content-space-between align-items-center width-100p">
														<groupui-text quiet="true" class="padding-left-05rem">{{
															t('APP.HEADER.VW_GROUP_EMPLOYEE_POLICY')
														}}</groupui-text>
														<groupui-icon
															class="padding-right-05rem color-petrol-primary"
															name="external-link-24"
															theme="vwag"></groupui-icon>
													</div>
												</groupui-select-option>
											</groupui-link>
										</div>
										<groupui-divider></groupui-divider>
										<groupui-link data-test="link-system-help" target="blank" quiet="true" [href]="complianceContent?.SystemHelp">
											<groupui-select-option>
												<div class="display-flex justify-content-space-between align-items-center width-100p">
													<groupui-text quiet="true" class="padding-left-05rem">{{ t('APP.HEADER.SYSTEM_HELP') }}</groupui-text>
													<groupui-icon
														class="padding-right-05rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-select-option>
										</groupui-link>

										<groupui-link data-test="link-privacy-policy" target="blank" quiet="true" [href]="complianceContent?.TermsOfUse">
											<groupui-select-option>
												<div class="display-flex justify-content-space-between align-items-center width-100p">
													<groupui-text quiet="true" class="padding-left-05rem">{{ t('APP.HEADER.TERMS_OF_USE') }}</groupui-text>
													<groupui-icon
														class="padding-right-05rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-select-option>
										</groupui-link>

										<groupui-link
											data-test="link-third-party-licenses"
											target="blank"
											quiet="true"
											[href]="complianceContent?.ThirdPartyLicenses">
											<groupui-select-option>
												<div class="display-flex justify-content-space-between align-items-center width-100p">
													<groupui-text quiet="true" class="padding-left-05rem">{{
														t('APP.HEADER.THIRD_PARTY_LICENSES')
													}}</groupui-text>
													<groupui-icon
														class="padding-right-05rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-select-option>
										</groupui-link>

										<groupui-link
											data-test="link-group-whistleblower"
											target="blank"
											quiet="true"
											[href]="complianceContent?.GroupWhistleblower">
											<groupui-select-option>
												<div class="display-flex justify-content-space-between align-items-center width-100p">
													<groupui-text quiet="true" class="padding-left-05rem">{{ t('APP.HEADER.VW_WHISTLEBLOWER') }}</groupui-text>
													<groupui-icon
														class="padding-right-05rem color-petrol-primary"
														name="external-link-24"
														theme="vwag"></groupui-icon>
												</div>
											</groupui-select-option>
										</groupui-link>
									</div>
								</groupui-action-area-popover-item>

								<groupui-action-area-popover-item>
									<!-- User Icon -->
									<span slot="parent"><groupui-icon name="user-32" theme="vwag"></groupui-icon></span>

									<!-- Action List -->
									<div slot="content" class="display-flex flex-direction-column min-width-300px">
										<groupui-link (click)="onLogout()">
											<groupui-select-option>
												<div class="display-flex justify-content-space-between align-items-center width-100p">
													<groupui-text quiet="true" class="padding-left-05rem color-petrol-primary">{{
														t('APP.HEADER.USER_SIGN_OUT')
													}}</groupui-text>
													<groupui-icon class="padding-right-05rem" name="logout-24" theme="vwag"></groupui-icon>
												</div>
											</groupui-select-option>
										</groupui-link>
									</div>
								</groupui-action-area-popover-item>
							</groupui-action-area>
						</div>
					</div>
				</groupui-grid-col>
			</groupui-grid-row>
		</groupui-grid>
	</groupui-header>
</ng-container>

<ng-container *transloco="let t; read: 'APP.FOOTER'">
	<div class="display-flex flex-direction-column align-items-center gap-groupui-spacing-7 padding-bottom-2rem">
		<!-- Upper footer area -->
		<div class="display-flex flex-direction-column align-items-center gap-groupui-spacing-3 width-100p">
			<groupui-divider></groupui-divider>
			<!-- Actions -->
			<div class="display-flex align-items-center">
				<groupui-popover [visible]="isContactPopoverVisible" btrClickOutside (clickOutside)="isContactPopoverVisible = false" placement="top">
					<span slot="parent" class="width-100px text-align-center">
						<groupui-link quiet variant="secondary" class="color-primary" (click)="toggleContactGroupuiPopover()">{{ t('CONTACT') }}</groupui-link>
					</span>
					<div slot="content" class="display-flex flex-direction-column min-width-300px">
						<groupui-link data-test="link-email" target="blank" href="mailto: {{ supportEmail }}">
							<groupui-select-option>
								<div class="display-flex justify-content-space-between align-items-center width-100p">
									<groupui-text class="padding-left-05rem">{{ t('EMAIL') }}</groupui-text>
									<groupui-icon class="padding-right-05rem" name="email-24" theme="vwag"></groupui-icon>
								</div>
							</groupui-select-option>
						</groupui-link>
					</div>
				</groupui-popover>
				<groupui-divider vertical class="height-2rem margin-left-05rem margin-right-05rem"></groupui-divider>
				<groupui-link
					class="width-100px text-align-center padding-inline-075rem"
					data-test="link-imprint"
					target="blank"
					quiet
					[href]="complianceContent?.Imprint">
					<groupui-link variant="secondary" quiet>{{ t('IMPRINT') }}</groupui-link>
				</groupui-link>
				<groupui-divider vertical class="height-2rem margin-left-05rem margin-right-05rem"></groupui-divider>
				<groupui-popover [visible]="isPrivacyPopoverVisible" btrClickOutside (clickOutside)="isPrivacyPopoverVisible = false" placement="top">
					<span slot="parent" class="width-100px text-align-center">
						<groupui-link quiet variant="secondary" class="color-primary" (click)="togglePrivacyGroupuiPopover()">{{ t('PRIVACY') }}</groupui-link>
					</span>
					<div slot="content" class="display-flex flex-direction-column min-width-300px">
						<groupui-link data-test="link-brand-employee-policy" target="blank" quiet="true" [href]="complianceContent?.VwBrandEmployeePolicy">
							<groupui-select-option>
								<div class="display-flex justify-content-space-between align-items-center width-100p">
									<groupui-text class="padding-left-05rem">{{ t('BRAND_EMPLOYEE_POLICY') }}</groupui-text>
									<groupui-icon class="padding-right-05rem color-petrol-primary" name="external-link-24" theme="vwag"></groupui-icon>
								</div>
							</groupui-select-option>
						</groupui-link>

						<groupui-link data-test="link-business-partner-policy" target="blank" quiet="true" [href]="complianceContent?.BusinessPartnerPolicy">
							<groupui-select-option>
								<div class="display-flex justify-content-space-between align-items-center width-100p">
									<groupui-text class="padding-left-05rem">{{ t('BUSINESS_PARTNER_POLICY') }}</groupui-text>
									<groupui-icon class="padding-right-05rem color-petrol-primary" name="external-link-24" theme="vwag"></groupui-icon>
								</div>
							</groupui-select-option>
						</groupui-link>

						<groupui-link data-test="link-privacy-policy" target="blank" quiet="true" [href]="complianceContent?.PrivacyPolicy">
							<groupui-select-option>
								<div class="display-flex justify-content-space-between align-items-center width-100p">
									<groupui-text class="padding-left-05rem">{{ t('PRIVACY_POLICY') }}</groupui-text>
									<groupui-icon class="padding-right-05rem color-petrol-primary" name="external-link-24" theme="vwag"></groupui-icon>
								</div>
							</groupui-select-option>
						</groupui-link>

						<groupui-link data-test="link-vw-group-employee-policy" target="blank" quiet="true" [href]="complianceContent?.VwGroupEmployeePolicy">
							<groupui-select-option>
								<div class="display-flex justify-content-space-between align-items-center width-100p">
									<groupui-text class="padding-left-05rem">{{ t('VW_GROUP_EMPLOYEE_POLICY') }}</groupui-text>
									<groupui-icon class="padding-right-05rem color-petrol-primary" name="external-link-24" theme="vwag"></groupui-icon>
								</div>
							</groupui-select-option>
						</groupui-link>
					</div>
				</groupui-popover>

				<groupui-divider vertical class="height-2rem margin-left-05rem margin-right-05rem"></groupui-divider>
				<groupui-link
					class="width-100px text-align-center padding-inline-075rem"
					data-test="link-system-help"
					quiet
					target="blank"
					[href]="complianceContent?.SystemHelp">
					<groupui-link variant="secondary" quiet>{{ t('SYSTEM_HELP') }}</groupui-link>
				</groupui-link>
				<groupui-divider vertical class="height-2rem margin-left-05rem margin-right-05rem"></groupui-divider>
				<groupui-link
					class="width-100px text-align-center padding-inline-075rem"
					data-test="link-terms-of-use"
					target="blank"
					quiet
					[href]="complianceContent?.TermsOfUse">
					<groupui-link variant="secondary" quiet>{{ t('TERMS_OF_USE') }}</groupui-link>
				</groupui-link>
			</div>
			<groupui-divider></groupui-divider>
		</div>
		<!-- VW Logo -->
		<div class="footer-image display-flex">
			<img src="assets/images/vwag_footer_img.svg" alt="vwag footer image" />
		</div>
	</div>
</ng-container>
